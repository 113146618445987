<template>
  <div class="home">
    home
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style lang="scss" scoped></style>
